
  import { mapGetters,mapActions } from 'vuex'
  export default {
    props:{
      region:{
        type:String,
        default:'UK',
      },
    },
    computed: {
      ...mapGetters({
        activeModal : 'pages/getActiveModal',
      }),
    },
    methods:{
        ...mapActions({
          updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
        }),
        updateModal(id){
          this.updateActiveModal(id)
          console.log('Active Modal: ',this.activeModal)
        }
    },
  }
  